// Partners.js
import React from "react";

const Partners = () => (
  <div>
    <h1>Partners</h1>
    <p>This is the partners page.</p>
  </div>
);

export default Partners;
