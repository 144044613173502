// HamburgerMenu.js
import React, {useState} from "react";
import "./HamburgerMenu.css"; // Import the CSS file
import {NavLink} from "react-router-dom";

const HamburgerMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      {/* Hamburger icon */}
      <div className="hamburger-icon" onClick={() => setShowMenu(!showMenu)}>
        <button className="dropdown-button">☰</button>
      </div>

      {/* Dropdown menu */}
      <div className={`dropdown-menu ${showMenu ? "show" : ""}`}>
        <NavLink to="/contact" activeclassname="active-link">
          Contact
        </NavLink>
        <NavLink to="/partners" activeclassname="active-link">
          Partners
        </NavLink>
        <NavLink to="/about" activeclassname="active-link">
          About
        </NavLink>
      </div>
    </div>
  );
};

export default HamburgerMenu;
