import React, {useState} from "react";
import "./HomePage.css";
import HamburgerMenu from "./HamburgerMenu";

const HomePage = () => {
  const [showText, setShowText] = useState({});

  const handleMouseOver = (id) => {
    setShowText({...showText, [id]: true});
  };

  const handleMouseOut = (id) => {
    setShowText({...showText, [id]: false});
  };
  return (
    <div className="home-page">
      <header className="header">
        <a href="/">
          <img
            className="header-img"
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="DutyFit logo"
          />
        </a>
        {/* <h1>DutyFit</h1> */}
        <div className="hamburger-menu">
          <HamburgerMenu />
        </div>
      </header>
      <section className="intros">
        <div className="intro">
          <h2>Welcome to DutyFit</h2>
        </div>
        <div className="intro">
          <p>
            DutyFit is a groundbreaking initiative designed to enhance the
            health and well-being of first responders, born from extensive
            research conducted at George Mason University’s Center for Adaptive
            Brain-Body Interactions (CASBBI) through funding from the National
            Science Foundation’s (NSF) Traineeship Program. This initiative
            emerged from collaborative efforts involving multiple stakeholders
            and aims to address critical issues identified through comprehensive
            feedback and research.
          </p>
          <h2>Why DutyFit?</h2>
          <p>
            DutyFit aims to create a holistic, wellness-oriented,
            community-centered platform for first responders. By fostering early
            intervention, enhancing peer and support networks, and developing
            comprehensive training programs, DutyFit seeks to transform first
            responder support.
          </p>
          <p>
            Through interviews with over 150 stakeholders—including
            firefighters, paramedics, peer support professionals, organizations,
            and family members—key themes emerged, each relevant to different
            user groups:
          </p>
        </div>
      </section>
      <section className="features">
        <div className="feature">
          <div
            className="image-container"
            onMouseOver={() => handleMouseOver("fireman")}
            onMouseOut={() => handleMouseOut("fireman")}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/fireman.png`}
              alt="Fireman holding helmet"
            />
            {showText["fireman"] && (
              <p>
                Building a robust peer support network where first responders
                can connect, share experiences, and support each other. DutyFit
                enhances peer-led initiatives with tools and resources to foster
                a supportive and resilient community.
              </p>
            )}
          </div>
          <h2>Peers</h2>
        </div>
        <div className="feature">
          <div
            className="image-container"
            onMouseOver={() => handleMouseOver("family")}
            onMouseOut={() => handleMouseOut("family")}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/family.png`}
              alt="Family holding hands"
            />
            {showText["family"] && (
              <p>
                Building a supportive family-peer network, our platform offers
                specific groups, psychoeducation, and collaborative efforts to
                enhance the resilience and well-being of families who understand
                the first responder lifestyle. Connect with others who share
                your unique experiences and challenges.
              </p>
            )}
          </div>
          <h2>Family</h2>
        </div>
        <div className="feature">
          <div
            className="image-container"
            onMouseOver={() => handleMouseOver("professional")}
            onMouseOut={() => handleMouseOut("professional")}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/professional.png`}
              alt="Professional"
            />
            {showText["professional"] && (
              <p>
                Creating an interdisciplinary professional network with a
                pathway toward cultural competency, DutyFit connects capable
                providers to meet the needs of first responders and their
                families.
              </p>
            )}
          </div>
          <h2>Professionals</h2>
        </div>
        <div className="feature">
          <div
            className="image-container"
            onMouseOver={() => handleMouseOver("fireside")}
            onMouseOut={() => handleMouseOut("fireside")}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/fireside.png`}
              alt="FireSide"
            />
            {showText["fireside"] && (
              <p>
                A repository for first responder-owned businesses, FireSide aims
                to build a professional network where first responders can
                connect, leverage opportunities, and support each other's
                ventures. Enhance your career and business through a dedicated
                first responder community..{" "}
              </p>
            )}
          </div>
          <h2>FireSide</h2>
        </div>
      </section>
      <section className="get-involved">
        <div className="getInvolved">
          <p>
            Help us build a stronger, healthier community for those who dedicate
            their lives to protecting others. <br /> By joining the DutyFit
            community, you can make a significant impact on the well-being of
            first responders and their families.
          </p>
          <a
            href="https://forms.gle/eiZcKF5P3Ej7MbhA7"
            className="button moveDown"
          >
            Get Involved
          </a>
        </div>
      </section>
      <div className="footer">
        <p>&copy; {new Date().getFullYear()} DutyFit</p>
      </div>
    </div>
  );
};

export default HomePage;
