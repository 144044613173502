// Contact.js
import React from "react";

const Contact = () => (
  <div>
    <h1>Contact</h1>
    <p>This is the Contact page.</p>
  </div>
);

export default Contact;
