// About.js
import React from "react";
import "./About.css";
import HamburgerMenu from "./HamburgerMenu";

const About = () => (
  <div className="about-page">
    <header className="header">
      <a href="/">
        <img
          className="header-img"
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="DutyFit logo"
        />
      </a>
      {/* <h1>About</h1> */}
      <div className="hamburger-menu">
        <HamburgerMenu />
      </div>
    </header>
    <img
      className="morgan-img"
      src={`${process.env.PUBLIC_URL}/images/morganMoore.png`}
      alt="DutyFit logo"
    />
    <section className="about-info">
      <div>
        <p>
          Morgan Moore is the Assistant Director of Community Health Initiatives
          at the Center for Adaptive Systems of Brain-Body Interactions (CASBBI)
          at George Mason University, where she leads the development of
          DutyFit, an integrated community health platform aimed at enhancing
          the mental and physical well-being of first responders and their
          families. With a Master of Social Work from George Mason University,
          Morgan brings a wealth of knowledge and experience in trauma-informed
          care, neurodiversity, and community engagement. As a supervisee at
          Sunstone Counseling, she provides therapy services to diverse
          populations, drawing on her extensive background in neuroscience and
          mental health.
        </p>
        <p>
          Morgan's commitment to first responders is deeply personal, being the
          spouse of a first responder and part of a military family. Her
          extensive volunteer work with organizations like the USO and Operation
          Homefront underscores her dedication to service and community.
          Additionally, Morgan has been recognized as a National Science
          Foundation I-Corps Trainee and a recipient of various fellowships,
          highlighting her leadership in neurodevelopmental and behavioral
          health research. Her work integrates a comprehensive, compassionate
          support system for those she serves, making significant strides in
          community health and well-being.
        </p>
      </div>
    </section>
  </div>
);

export default About;
